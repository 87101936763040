import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';

/**
 * Duplicated in _inline-list.scss.
 */
const StyledInlineList = styled.ul.withConfig({
  componentId: "sg1660__sc-10wzf9m-0"
})(["display:flex;flex-wrap:wrap;list-style:none;margin:0;padding-left:0;"]);
const StyledInlineListItem = styled.li.withConfig({
  componentId: "sg1660__sc-10wzf9m-1"
})(["&:not(:last-child)::after{content:'|';margin:0 ", ";}"], tokens.space.xs);

export { StyledInlineList, StyledInlineListItem };
